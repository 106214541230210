<template>
  <v-card class="segmented question-group" flat>
    <v-card-title class="d-block" v-if="localize(value.title)">
      {{ localize(value.title) }}

      <template v-if="value.subtitleAsTooltip && localize(value.subtitle)">
        <v-tooltip left max-width="500">
          <template v-slot:activator="{ on: tooltip }">
            <v-icon v-on="{ ...tooltip }">mdi-information</v-icon>
          </template>
          <span v-html="localize(value.subtitle)"></span>
        </v-tooltip>
      </template>
    </v-card-title>
    <v-card-text>
      <div>
        <v-alert
          v-if="localize(value.subtitle)"
          dense
          text
          type="info"
          class="mb-6"
        >
          <span v-html="localize(value.subtitle)"></span>
        </v-alert>

        <template v-if="value.hasCondition">
          <div class="d-flex">
            <v-switch
              v-if="!printView"
              v-model="conditionIsMet"
              color="secondary"
              class="mt-0 pt-0"
              hide-details
              :label="localize(value.condition)"
              :disabled="readOnly"
              @change="itemChanged"
            ></v-switch>
            <v-checkbox
              v-else
              v-model="conditionIsMet"
              color="secondary"
              class="mt-0 pt-0"
              hide-details
              :label="localize(value.condition)"
              disabled
            ></v-checkbox>
          </div>
        </template>

        <v-expand-transition>
          <div
            v-show="!value.hasCondition || conditionIsMet"
            :class="{
              expanded: value.hasCondition,
              'mt-4': value.hasCondition
            }"
          >
            <grouped-element
              v-for="(item, index) in value.elements"
              v-model="value.elements[index]"
              :key="`form_element_${item.id}`"
              :readOnly="readOnly"
              :printView="printView"
              :isGroupedElement="true"
              @errorOnSave="$emit('errorOnSave')"
            ></grouped-element>
          </div>
        </v-expand-transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formElement from '@/components/forms/questionnaire/element.vue';

import { mapActions, mapState } from 'vuex';

export default {
  name: 'groupElement',
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    groupedElement: formElement
  },

  data: () => ({
    conditionIsMet: false
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    conditionLabel() {
      if (this.conditionIsMet) {
        return this.$t('yes');
      } else {
        return this.$t('no');
      }
    }
  },

  watch: {
    conditionIsMet() {
      if (this.conditionIsMet) {
        this.value.value = 1;
      } else {
        this.value.value = 0;
      }
    }
  },

  mounted() {
    if (this.value.value === 1) {
      this.conditionIsMet = true;
    }

    if (
      this.feedbackData &&
      this.feedbackData.isComparison &&
      this.value.hasCondition
    ) {
      for (let comparisonData of this.feedbackData.comparisonData) {
        const comparisonItem = comparisonData.values.filter(
          (x) => x.elementId === this.value.id
        );

        if (comparisonItem.length === 0) {
          continue;
        }

        if (comparisonItem[0].value === 1) {
          this.value.value = 1;
          this.conditionIsMet = true;

          break;
        }
      }
    }
  },

  methods: {
    ...mapActions({
      saveItem: 'feedbacks/saveItemValue'
    }),

    itemChanged() {
      var itemValue = this.conditionIsMet ? 1 : 0;

      this.saveItem({
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        value: itemValue
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.expanded {
  background-color: var(--element-background-secondary);
  margin-left: -1em;
  margin-right: -1em;
  padding: 1em;
}

.question-group {
  .questionnaire-element-wrapper:last-of-type {
    margin-bottom: 1em;
  }
}
</style>
